// We keep the type for the enum in a separate file so that we can import it
// into both client and server code.
import { type EnumObjectValues, type EnumObjectKeys } from '~/utils/types.ts'

export const RoleEnum = {
  USER: 'user',
  PROFESSIONAL: 'professional',
  ADMIN: 'admin',
} as const
export type RoleType = EnumObjectValues<typeof RoleEnum>
export type RoleKeysType = EnumObjectKeys<typeof RoleEnum>

export const StatusEnum = {
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
  PENDING: 'pending',
  ALL: 'all',
} as const
export type StatusType = EnumObjectValues<typeof StatusEnum>
export type StatusKeysType = EnumObjectKeys<typeof StatusEnum>

// domingo primero, en js domingo es el dia 0
export const WeekDayEnum = {
  SUNDAY: 'Domingo',
  MONDAY: 'Lunes',
  TUESDAY: 'Martes',
  WEDNESDAY: 'Miércoles',
  THURSDAY: 'Jueves',
  FRIDAY: 'Viernes',
  SATURDAY: 'Sábado',
} as const
export type WeekDayType = EnumObjectValues<typeof WeekDayEnum>
export type WeekDayKeysType = EnumObjectKeys<typeof WeekDayEnum>

export const WeekDayAbrevEnum = {
  MONDAY: 'Lun',
  TUESDAY: 'Mar',
  WEDNESDAY: 'Mie',
  THURSDAY: 'Jue',
  FRIDAY: 'Vie',
  SATURDAY: 'Sab',
  SUNDAY: 'Dom',
  //continuar con todos los dias de la semana sin usar acentos
} as const
export type WeekDayAbrevType = EnumObjectValues<typeof WeekDayAbrevEnum>
export type WeekDayAbrevKeysType = EnumObjectKeys<typeof WeekDayAbrevEnum>

export const LanguageEnum = {
  SPANISH: 'Español',
  ENGLISH: 'Inglés',
  FRENCH: 'Francés',
  CATALAN: 'Catalán',
  BASQUE: 'Euskera',
  ITALIAN: 'Italiano',
} as const
export type LanguageType = EnumObjectValues<typeof LanguageEnum>
export type LanguageKeysType = EnumObjectKeys<typeof LanguageEnum>

export const CouponStatusEnum = {
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
  CLOSED: 'closed',
} as const
export type CouponStatusType = EnumObjectValues<typeof CouponStatusEnum>
export type CouponStatusKeysType = EnumObjectKeys<typeof CouponStatusEnum>

export enum ActionTypeEnum {
  status = 'status',
  create = 'create',
  delete = 'delete',
  edit = 'edit',
  download = 'download',
  enableDisable = 'enableDisable',
  photo = 'photo',
}
export type ActionType = EnumObjectValues<typeof ActionTypeEnum>
export type ActionKeysType = EnumObjectKeys<typeof ActionTypeEnum>

export const PaymentEnum = {
  PAYPAL: 'paypal',
  STRIPE: 'stripe',
} as const
export type PaymentType = EnumObjectValues<typeof PaymentEnum>
export type PaymentKeysType = EnumObjectKeys<typeof PaymentEnum>

export const PaymentMethodEnum = {
  STRIPE: 'card',
  PAYPAL: 'paypal',
  COUPON: 'coupon',
} as const
export type PaymentMethodEnumType = EnumObjectValues<typeof PaymentMethodEnum>

export const NotificationEventEnum = {
  AFTER_BUY: 'Después de la compra',
  BEFORE_SESSION: 'Antes de la sesión',
  AFTER_SESSION: 'Después de la sesión',
  RESCHEDULE_SESSION: 'Después de reprogramar sesión',
  CANCEL_SESSION: 'Después de cancelar sesión',
  LATE_ON_VIDEO_CALL: 'Al llegar tarde en una videollamada',
  AFTER_UPLOAD_SESSIONFILE: 'Después subir un archivo de sesión',
  WHEN_START_SESSION_RECORDING: 'Al empezar a grabar una sesión',
  WHEN_GOOGLE_CALENDAR_TOKEN_EXPIRE:
    'Cuando caduca el token de google calendar',
} as const
export type NotificationEventType = EnumObjectValues<
  typeof NotificationEventEnum
>
export type NotificationEventeysType = EnumObjectKeys<
  typeof NotificationEventEnum
>

export const IVAEnum = {
  YES: 'Si',
  NO: 'No',
} as const
export type IVAType = EnumObjectValues<typeof IVAEnum>
export type IVAKeysType = EnumObjectKeys<typeof IVAEnum>

export const IrpfEnum = {
  '7%': 7,
  '15%': 15,
} as const
export type IrpfType = EnumObjectValues<typeof IrpfEnum>
export type IrpfKeysType = EnumObjectKeys<typeof IrpfEnum>

export const NotificationStatusEnum = {
  PENDING: 'pending',
  SENDED: 'sended',
  ERROR: 'error',
} as const
export type NotificationStatusType = EnumObjectValues<
  typeof NotificationStatusEnum
>
export type NotificationStatusKeysType = EnumObjectKeys<
  typeof NotificationStatusEnum
>

export const EnvEnum = {
  PRODUCTION: 'production',
  STAGING: 'staging',
  DEV: 'dev',
} as const
export type ModeEnvType = EnumObjectValues<typeof EnvEnum>
export type ModeEnvKeysType = EnumObjectKeys<typeof EnvEnum>

export enum PaymentErrorEnum {
  GENERIC = 'true',
  PAYMENT = 'payment', // if payment was made but there was a error creating db data
  SESSION_EXIST = 'sessionExist', // if payment was made but session already exist
}
export type PaymentErrorEnumType = EnumObjectValues<typeof PaymentErrorEnum>
export type PaymentErrorEnumKeysType = EnumObjectKeys<typeof PaymentErrorEnum>

export const HomeFilterEnum = {
  CATEGORIES: 'categories',
  HOUR: 'hour',
  TODAY: 'today',
  TOPICS: 'topics',
  TYPE: 'type',
  SEARCH: 'search',
} as const
export type HomeFilterEnumType = EnumObjectValues<typeof HomeFilterEnum>

export const SessionTypeEnum = {
  WRITTEN: 'escrito',
  VIDEO_CALL: 'videollamada',
} as const

export const CurrencyEnum = {
  EUR: 'EUR',
  AED: 'AED',
  AFN: 'AFN',
  ALL: 'ALL',
  AMD: 'AMD',
  ANG: 'ANG',
  AOA: 'AOA',
  ARS: 'ARS',
  AUD: 'AUD',
  AWG: 'AWG',
  AZN: 'AZN',
  BAM: 'BAM',
  BBD: 'BBD',
  BDT: 'BDT',
  BGN: 'BGN',
  BHD: 'BHD',
  BIF: 'BIF',
  BMD: 'BMD',
  BND: 'BND',
  BOB: 'BOB',
  BRL: 'BRL',
  BSD: 'BSD',
  BTN: 'BTN',
  BWP: 'BWP',
  BYN: 'BYN',
  BZD: 'BZD',
  CAD: 'CAD',
  CDF: 'CDF',
  CHF: 'CHF',
  CLP: 'CLP',
  CNY: 'CNY',
  COP: 'COP',
  CRC: 'CRC',
  CUP: 'CUP',
  CVE: 'CVE',
  CZK: 'CZK',
  DJF: 'DJF',
  DKK: 'DKK',
  DOP: 'DOP',
  DZD: 'DZD',
  EGP: 'EGP',
  ERN: 'ERN',
  ETB: 'ETB',
  FJD: 'FJD',
  FKP: 'FKP',
  FOK: 'FOK',
  GBP: 'GBP',
  GEL: 'GEL',
  GGP: 'GGP',
  GHS: 'GHS',
  GIP: 'GIP',
  GMD: 'GMD',
  GNF: 'GNF',
  GTQ: 'GTQ',
  GYD: 'GYD',
  HKD: 'HKD',
  HNL: 'HNL',
  HRK: 'HRK',
  HTG: 'HTG',
  HUF: 'HUF',
  IDR: 'IDR',
  ILS: 'ILS',
  IMP: 'IMP',
  INR: 'INR',
  IQD: 'IQD',
  IRR: 'IRR',
  ISK: 'ISK',
  JEP: 'JEP',
  JMD: 'JMD',
  JOD: 'JOD',
  JPY: 'JPY',
  KES: 'KES',
  KGS: 'KGS',
  KHR: 'KHR',
  KID: 'KID',
  KMF: 'KMF',
  KRW: 'KRW',
  KWD: 'KWD',
  KYD: 'KYD',
  KZT: 'KZT',
  LAK: 'LAK',
  LBP: 'LBP',
  LKR: 'LKR',
  LRD: 'LRD',
  LSL: 'LSL',
  LYD: 'LYD',
  MAD: 'MAD',
  MDL: 'MDL',
  MGA: 'MGA',
  MKD: 'MKD',
  MMK: 'MMK',
  MNT: 'MNT',
  MOP: 'MOP',
  MRU: 'MRU',
  MUR: 'MUR',
  MVR: 'MVR',
  MWK: 'MWK',
  MXN: 'MXN',
  MYR: 'MYR',
  MZN: 'MZN',
  NAD: 'NAD',
  NGN: 'NGN',
  NIO: 'NIO',
  NOK: 'NOK',
  NPR: 'NPR',
  NZD: 'NZD',
  OMR: 'OMR',
  PAB: 'PAB',
  PEN: 'PEN',
  PGK: 'PGK',
  PHP: 'PHP',
  PKR: 'PKR',
  PLN: 'PLN',
  PYG: 'PYG',
  QAR: 'QAR',
  RON: 'RON',
  RSD: 'RSD',
  RUB: 'RUB',
  RWF: 'RWF',
  SAR: 'SAR',
  SBD: 'SBD',
  SCR: 'SCR',
  SDG: 'SDG',
  SEK: 'SEK',
  SGD: 'SGD',
  SHP: 'SHP',
  SLE: 'SLE',
  SLL: 'SLL',
  SOS: 'SOS',
  SRD: 'SRD',
  SSP: 'SSP',
  STN: 'STN',
  SYP: 'SYP',
  SZL: 'SZL',
  THB: 'THB',
  TJS: 'TJS',
  TMT: 'TMT',
  TND: 'TND',
  TOP: 'TOP',
  TRY: 'TRY',
  TTD: 'TTD',
  TVD: 'TVD',
  TWD: 'TWD',
  TZS: 'TZS',
  UAH: 'UAH',
  UGX: 'UGX',
  USD: 'USD',
  UYU: 'UYU',
  UZS: 'UZS',
  VES: 'VES',
  VND: 'VND',
  VUV: 'VUV',
  WST: 'WST',
  XAF: 'XAF',
  XCD: 'XCD',
  XDR: 'XDR',
  XOF: 'XOF',
  XPF: 'XPF',
  YER: 'YER',
  ZAR: 'ZAR',
  ZMW: 'ZMW',
  ZWL: 'ZWL',
} as const
export type CurrencyEnumEnumType = EnumObjectValues<typeof CurrencyEnum>
